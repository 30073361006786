<template>
  <div class="sign-page mb-14">
    <div class="max-width">
      <v-row align="center">
        <v-col cols="12" md="5" class="text-center">
          <img width="100%" :src="require('@/assets/images/auth/bg.png')" />
        </v-col>
        <!-- page content -->
        <v-col cols="12" md="6" offset-md="1" class="white">
          <div
            class="
              sign-page__content
              d-flex
              align-center
              justify-center
              h-100
              z-4
              relative
              mt-6
              mb-14
            "
          >
            <div class="w-100 sign-page__content__form mt-6">
              <span class="d-block primary--text font-500 font-40 mt-12">{{
                $t("signIn")
              }}</span>

              <!-- form -->
              <v-form
                class="w-100 mt-10"
                v-model="valid"
                @submit.prevent="submitHandler"
                ref="signinForm"
              >
                <!-- email -->
                <base-input
                  :placeholder="$t('email')"
                  v-model="user.email"
                  :rules="$helper.emailRules"
                ></base-input>

                <!-- password -->
                <base-input
                  class="mt-6"
                  :placeholder="$t('password')"
                  v-model="user.password"
                  type="password"
                ></base-input>

                <!-- actions -->
                <v-btn
                  color="primary"
                  class="mt-10"
                  block
                  height="45"
                  type="submit"
                  :disabled="!valid"
                  ><span class="font-22 white--text" :class="!valid?'primary--text':'white--text'">{{
                    $t("signIn")
                  }}</span>
                  <v-progress-circular
                    v-if="loading"
                    :size="15"
                    color="lightGreyText"
                    indeterminate
                  ></v-progress-circular>
                </v-btn>
              </v-form>
              <div class="text-center">
                <span class="d-block black--text font-22 mt-7 mb-4"
                  >{{ $t("registerMessage") }}
                  <router-link to="/sign-up">
                    <span class="lightblue--text">{{
                      $t("signUp")
                    }}</span></router-link
                  ></span
                >
              </div>

              <!-- facebook -->
              <!-- <v-btn class="c-btn-gradient" rounded 
                min-width="160"
                height="35">
              <div class="px-4 py-2 d-flex align-center">
                <img
                  class="mr-6"
                  :src="require('@/assets/images/global/facebook-char.png')"
                  alt=""
                />
                <span class="font-15 font-300 white--text"
                  >Sign in with Facebook</span
                >
              </div>
            </v-btn> -->
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    valid: false,
    loading: false,
    user: {},
  }),
  computed: {
    ...mapGetters(["fcmToken"]),
  },
  methods: {
    async submitHandler() {
      let valid = this.$refs.signinForm.validate();
      this.loading = true;
      if (!valid) return;
      await this.$axios
        .post("/auth/login", {
          ...this.user,
        })
        .then((res) => {
          this.processValadtion(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async processValadtion(res) {
      this.loading = false;
      if (res.data.success) {
        res.data.userLogged = true;
        this.$store.dispatch("setUser", res.data);
        this.$store.dispatch("showSnack", {
          text: "logged successfully",
          color: "success",
        });
        this.$axios.defaults.headers.common = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
        let fData = new FormData();
        fData.append("token", this.fcmToken);
        await this.$axios.post("/save-token", fData);
        localStorage.removeItem("verifyEmail");
        this.$router.replace("/");
      } else {
        if (res.message == "The email not verified") {
          localStorage.setItem("verifyEmail", this.user.email);
          this.$router.replace("/verify-account");
        }
        this.$store.dispatch("showSnack", {
          text: res.data.message,
          color: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss">
.sign-page {
  margin-top: 140px;
  &__content {
    max-width: 550px;
    margin: auto;
  }
}
</style>
